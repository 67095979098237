import React from "react"

import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

// FOR EVENT Detail
import Blocks from "editorjs-blocks-react-renderer"
import { EventDetailTextTwo, BlogDetailTextOne } from "../components/typography"

const PrivacyPolicy = ({ data, location }) => {
  const myData =
    data.strapiPrivacyPolicy?.Content?.data?.childMarkdownRemark &&
    JSON.parse(
      data.strapiPrivacyPolicy?.Content?.data?.childMarkdownRemark
        ?.rawMarkdownBody
    )

  return (
    <Layout
      ctaColor="bg-yellowdark-50"
      textCtaColor="text-fontpurple-50"
      location={location}
      fullHeader
      title="Event Detail"
    >
      <div className="bg-white pb-20">
        <Seo title="Event detail" />
        <div className="h-96  bg-purplebglight-50 w-full" objectFit="cover" />
        <div
          className="relative  bg-white  rounded-2xl lg:rounded-4xl text-white text-center  -mt-12 max-w-7xl mx-auto
                 px-4
                 py-6
                 sm:px-20
                 sm:py-10
                 flex
                 flex-col
                 md:flex-row
                 flex-nowrap
                 "
        >
          <div
            className="flex flex-row justify-between md:flex-col md:justify-start md:mt-10 md:ml-10 md:order-2
              flex-nowrap
              justify-start
              items-center
              mb-8"
          ></div>
          <div className="mt-4 lg:mt-10 lg:pr-14">
            <BlogDetailTextOne additionalClassName="text-left text-fontpurple-50 mb-12 font-primary-bold text-3xl lg:text-[50px] lg:!leading-[56px]">
              Privacy Policy
            </BlogDetailTextOne>
            <EventDetailTextTwo additionalClassName="text-left mb-12 font-primary-bold">
              {data.strapiBlog?.subtitle}
            </EventDetailTextTwo>
            <Blocks
              data={myData}
              className="w-9/12"
              config={{
                code: {
                  className: "language-js",
                },
                delimiter: {
                  className: "border border-2 w-16 mx-auto",
                },
                embed: {
                  className: "border-0",
                },
                bold: {
                  className: "font-bold",
                },
                header: {
                  className:
                    "text-left text-xl text-black font-primary-bold pb-6",
                },
                image: {
                  className: "w-full pb-16",
                  actionsClassNames: {
                    stretched: "w-full h-80 object-cover",
                    withBorder: "border border-2",
                    withBackground: "p-2",
                  },
                },
                list: {
                  className:
                    "list-inside list-disc text-left text-black text-lg pb-10 font-inter",
                },
                paragraph: {
                  className: "text-left text-black text-lg pb-10 font-inter",
                },
                figcaption: {
                  className: "hidden",
                },
                quote: {
                  className: "py-3 px-5 italic font-serif",
                },
                table: {
                  className: "table-auto",
                },
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy

export const blogQuery = graphql`
  query ImpressumThird {
    strapiPrivacyPolicy {
      Content {
        data {
          childMarkdownRemark {
            id
            rawMarkdownBody
          }
        }
      }
    }
  }
`
